import React, { useState, useEffect } from "react";
import axios from "../helper/client";

function VerifyAdmin() {
  const [data, setData] = useState<any>("");

  const loadData = async () => {
    const result = await axios.post("/api/verifyAdmin");
    setData(result.data.flag);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <br />
      <h2>You are an administrator now</h2>
      <h5>Luffy: Did you know there's an awesome treasure hidden at the end of the sea? And anyone who finds it [will be] "King Of The Pirates"? Is that exciting or what?! There are incredible adventures out there, just waiting for us! 
      </h5>
      <br />
      <p style={{ color: "white"}}>{data}</p>
    </div>
  );
}

export default VerifyAdmin;
